import React, { useCallback, useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import AdminModal from 'components/admin2/ui/AdminModal';
import {
  AccessControlModalContainer,
  ExitButton,
  Header,
  AccessControlTitle,
  AccessControlDescription,
  AdvancedOptionsText,
  CardItemsContainer,
  Footer,
  DoneButton,
  NoVisibleEntitlementsWarning,
  WarningIcon,
  ChangesLabelContainer,
  AccessControlCardItem,
  NextButton,
  LockIcon,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import IGate, { GateKind } from 'models/IGate';
import ISubscription from 'models/ISubscription';
import IBundle from 'models/IBundle';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanFeatures } from 'services/billing';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { AmazonBenefitData } from './AmazonBenefitGateSettings';
import { gates } from './AccessControlGates';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import IState from 'services/state';

export interface ISubscriptionData {
  bundles: Array<IBundle>;
  hiddenBundles: string[];
  hiddenEntitlements: string[];
  subscriptions: Array<ISubscription>;
}

interface IAccessControlModal {
  accessGate: IGate;
  handleClose: () => void;
  initialPassword?: string;
  setAccessType: (
    kind: GateKind,
    password?: string,
    subscriptionsData?: ISubscriptionData,
    amazonBenefitData?: AmazonBenefitData,
  ) => void;
}

export default function AccessControlModal({
  accessGate,
  initialPassword,
  handleClose,
  setAccessType,
}: IAccessControlModal) {
  const dispatch = useDispatch();
  const [accessGateStateChanges, setAccessGateStateChanges] =
    useState(accessGate);
  const [password, setPassword] = useState(initialPassword);
  const { kind } = accessGateStateChanges || { kind: GateKind.NONE };
  const defaultSelectedGate = kind !== GateKind.NONE ? kind : GateKind.SUBSCRIPTION;
  const [selectedGate, setSelectedGate] = useState(defaultSelectedGate);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(
    kind === GateKind.ACCESSCODE,
  );
  const [accessCodeEnabled, setAccessCodeEnabled] = useState(
    kind === GateKind.ACCESSCODE,
  );
  const [hasGateError, setHasGateError] = useState(false);
  const [noVisibleEntitlementsWarning, setNoVisibleEntitlementsWarning] = useState(false);
  const [showSelectedGateSettings, setShowSelectedGateSettings] =
    useState(false);
  const [isGateOnFinalStep, setIsGateOnFinalStep] = useState((gates[selectedGate] && !gates[selectedGate].component) || false);
  const [amazonBenefitData, setAmazonBenefitData] = useState<AmazonBenefitData>(accessGateStateChanges.gate?.amazonBenefitData || {
    amazonBenefitId: '',
    amazonBenefitPurchaseUrl: '',
    benefitType: 'single',
    seriesBenefitMapping: [
      {
        amazonBenefitId: '',
        seriesId: uuid(),
      },
    ],
  });
  const planCanHoldFreeEvents = useSelector(getPlanFeatures)?.freeEvents;
  const featureGates = useSelector((state: IState) => getActiveSiteFeatures(state));

  useEffect(() => {
    if (kind !== GateKind.NONE) {
      const gateSelected = kind === GateKind.ACCESSCODE ? GateKind.SUBSCRIPTION : kind;
      setShowSelectedGateSettings(true);
      if(gates[gateSelected] && !gates[gateSelected].component) {
        setIsGateOnFinalStep(true);
      }
    }
  }, []);

  const completeChanges = useCallback(() => {
    const subscriptionsData = {
      subscriptions: accessGateStateChanges.gate?.subscriptions || [],
      bundles: accessGateStateChanges.gate?.bundles,
      hiddenEntitlements: accessGateStateChanges.gate?.hiddenEntitlements,
      hiddenBundles: accessGateStateChanges.gate?.hiddenBundles,
    };
    setAccessType(kind, password, subscriptionsData, amazonBenefitData);
    handleClose();
  }, [kind, password, accessGateStateChanges, amazonBenefitData]);

  const handleSelection = (gateSelected: GateKind) => () => {
    /**
     * If the user selects the password gate and they are not on an unlimited plan or custom plan, ask to upgrade plan
     */
    if (gateSelected === GateKind.PASSWORD && !planCanHoldFreeEvents) {
      handleClose();
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage:
              'ADMIN_UPGRADE_PLAN_LANDING_PASSWORD_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    if (gateSelected === GateKind.LOGIN && !planCanHoldFreeEvents) {
      handleClose();
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_LOGIN_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    if (gateSelected !== GateKind.NONE) {
      setSelectedGate(gateSelected);
      setAccessGateStateChanges((changes) => ({
        ...changes,
        kind: gateSelected,
      }));
    }
    if (gates[gateSelected].component) {
      setIsGateOnFinalStep(false);
    } else {
      setIsGateOnFinalStep(true);
    }
  };

  const handleAdvancedOptionsChange = useCallback(() => {
    setShowAdvancedOptions(!showAdvancedOptions);
  }, [showAdvancedOptions]);

  const isAccessCodeOrSubscriptionKind = useCallback(
    () =>
      selectedGate === GateKind.SUBSCRIPTION ||
      selectedGate === GateKind.ACCESSCODE,
    [selectedGate],
  );

  const handleNext = useCallback(() => {
    setShowSelectedGateSettings(true);
  }, [showSelectedGateSettings]);

  const featureFlagged = (gateType: string) => {
    const gate = gates[gateType];
    if(gate.featureGate) {
      if(featureGates[gate.featureGate]) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const getAccessControlCards = useCallback(() => {
    const gateCards: JSX.Element[] = [];
    for (const gateType of Object.keys(gates)) {
      const realSelectedGate = selectedGate === GateKind.ACCESSCODE ? GateKind.SUBSCRIPTION.toString() : selectedGate;
      if (featureFlagged(gateType)) {
        gateCards.push(
          (
            <AccessControlCardItem
              data-testid={gates[gateType].dataTestId}
              key={gateType}
              onClick={handleSelection(gateType as GateKind)}
              selected={realSelectedGate === gateType}
              iconKey={gates[gateType].iconKey}
              titleKey={gates[gateType].titleKey}
              descriptionKey={gates[gateType].descriptionKey}
              width="49%"
            />
          ),
        );
      }
    }
    return gateCards;
  }, [selectedGate]);

  const renderButton = useCallback(() => {
    if (!isGateOnFinalStep) {
      return (
        <NextButton
          data-testid="nextButton"
          onClick={handleNext}
          disabled={hasGateError}
        >
          <TranslatedText stringKey="ACCESS_CONTROL_NEXT" />
        </NextButton>
      );
    } else {
      return (
        <DoneButton
          data-testid="doneButton"
          onClick={completeChanges}
          disabled={hasGateError}
        >
          <TranslatedText stringKey="ACCESS_CONTROL_DONE" />
        </DoneButton>
      );
    }
  }, [hasGateError, isGateOnFinalStep, completeChanges, showSelectedGateSettings]);

  const handleChangeGateClick = () => {
    setIsGateOnFinalStep(false);
    setShowSelectedGateSettings(false);
  };

  return (
    <AdminModal
      fixedHeight={true}
      onClose={handleClose}
      allowOverflow={true}
      overlayStyles={{ top: '-20%' }}
    >
      <AccessControlModalContainer>
        <Header>
          <LockIcon />
          <AccessControlTitle>
            <TranslatedText stringKey="ACCESS_CONTROL" />
          </AccessControlTitle>
          <ChangesLabelContainer>
            <ExitButton onClick={handleClose} />
          </ChangesLabelContainer>
        </Header>
            <AccessControlDescription>
              {!showSelectedGateSettings && (<TranslatedText stringKey="ACCESS_CONTROL_DESCRIPTION" />)}
              {showSelectedGateSettings && isAccessCodeOrSubscriptionKind() && (
                <AdvancedOptionsText
                  data-testid="showAdvancedOptionsButton"
                  onClick={handleAdvancedOptionsChange}
                >
                  <TranslatedText
                    stringKey={
                      showAdvancedOptions
                        ? 'ACCESS_CONTROL_HIDE_ADVANCED_OPTIONS'
                        : 'ACCESS_CONTROL_SHOW_ADVANCED_OPTIONS'
                    }
                  />
                </AdvancedOptionsText>
              )}
            </AccessControlDescription>
            {!showSelectedGateSettings && (
              <CardItemsContainer>{getAccessControlCards()}</CardItemsContainer>
            )}
        {showSelectedGateSettings &&
          gates[selectedGate === GateKind.ACCESSCODE ? GateKind.SUBSCRIPTION : selectedGate].component &&
          gates[selectedGate === GateKind.ACCESSCODE ? GateKind.SUBSCRIPTION : selectedGate].component({
            accessGateStateChanges,
            setAccessGateStateChanges,
            password,
            amazonBenefitData,
            setAmazonBenefitData,
            setPassword,
            hasGateError,
            setHasGateError,
            setNoVisibleEntitlementsWarning,
            accessCodeEnabled,
            setAccessCodeEnabled,
            showAdvancedOptions,
            setShowAdvancedOptions,
            completeChanges,
            handleChangeGateClick,
            setIsGateOnFinalStep,
          })
        }
        <Footer>
          {noVisibleEntitlementsWarning && (
            <NoVisibleEntitlementsWarning>
              <WarningIcon />
              <TranslatedText stringKey="ACCESS_CONTROL_NO_VISIBLE_ENTITLEMENTS_WARNING" />
            </NoVisibleEntitlementsWarning>
          )}
          {renderButton()}
        </Footer>
      </AccessControlModalContainer>
    </AdminModal>
  );
}
